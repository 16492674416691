import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl as MuiFormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  Tabs,
  Box,
  Tab,
  Card,
  CardContent,
  TextField,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { spacing, useTheme } from "@mui/system";
import styled from "@emotion/styled";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Settings from "./Settings";
import { ChipGreen } from "../style";
import { useColumnsDettagli } from "./columnsDettagli";
import NuovoServizio from "./Nuovo Servizio e Bot/NuovoServizio";
import { showSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  min-width: 200px;
`;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const stati = [
  { nome: "Errore", codice: 4 },
  { nome: "Non Gestibile", codice: 5 },
  { nome: "Pending", codice: 2 },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const Modale = ({
  open,
  setOpen,
  dati,
  openFrom,
  servizioProp,
  codiceServizioProp,
  botProp,
  idUidBotProp,
  statoProp,
  codiceStatoProp,
  scopeProp,
  codiceScopeProp,
}) => {
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const [valueTabs, setValueTabs] = useState(0);
  const [openModalNuovoServizio, setOpenModalNuovoServizio] = useState(false);

  //States of select labels
  const [scope, setScope] = useState("");
  const [servizio, setServizio] = useState("");
  const [bot, setBot] = useState("");
  const [stato, setStato] = useState("");

  //States of select codes
  const [codiceServizio, setCodiceServizio] = useState(0);
  const [codiceScope, setCodiceScope] = useState(0);
  //useRef for Filters
  const botRef = useRef("");
  const statoRef = useRef(0);

  //States of button search
  const [buttonClick, setButtonClick] = useState(false);

  const [totaleDati, setTotaleDati] = useState();
  const [dataArray, setDataArray] = useState([]);
  const [info, setInfo] = useState([]);

  //States of Ricerca
  const [searchParam, setSearchParam] = useState("");
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  //State of Datagrid page size
  const [pageSize, setPageSize] = useState(5);

  const navigate = useNavigate();
  const theme = useTheme();

  const columns = useColumnsDettagli();

  const initializeModalState = (openFrom) => {
    const configurations = {
      PulsanteCentrale: {
        scope: scopeProp,
        servizio: "",
        bot: "",
        stato: "",
        codiceServizio: 0,
        idUidBot: "",
        codiceStato: 0,
        codiceScope: codiceScopeProp,
      },
      TabellaRiepilogo: {
        scope: scopeProp,
        servizio: "",
        bot: "",
        stato: statoProp,
        codiceServizio: 0,
        idUidBot: "",
        codiceStato: codiceStatoProp,
        codiceScope: codiceScopeProp,
      },
      TabellaPrincipale: {
        scope: scopeProp,
        servizio: servizioProp,
        bot: botProp,
        stato: "Errore", // Imposta errore di default
        codiceServizio: codiceServizioProp,
        idUidBot: idUidBotProp,
        codiceStato: codiceStatoProp,
        codiceScope: codiceScopeProp,
      },
    };

    const config = configurations[openFrom];
    setScope(config.scope);
    setServizio(config.servizio);
    setBot(config.bot);
    setStato(config.stato);
    setCodiceServizio(config.codiceServizio);
    botRef.current = config.idUidBot;
    statoRef.current = config.codiceStato;
    setCodiceScope(config.codiceScope);
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setTotaleDati(0);
    setDataArray([]);
    setInfo([]);
    setValueTabs(0);
    setButtonClick(false);
    initializeModalState(openFrom);
  };

  //Handle Change Select Labels
  const handleChangeScope = (event) => {
    setScope(event.target.value); //Set label scope as event
    setServizio(""); //Set label servizio as ''
    setBot(""); //Set label bot as ''
  };

  const handleChangeServizio = (event) => {
    setServizio(event.target.value); //Set label servizio as event
    setBot(""); //Set label bot as ''
  };

  const handleChangeBot = (event) => {
    setBot(event.target.value); //Set label bot as event
  };

  const handleChangeStato = (event) => {
    setStato(event.target.value); //Set label stato as event
  };
  //

  const handleClickButton = () => {
    servicesGetDetails({
      codice: codiceServizio,
      scope: codiceScope,
    });
  };

  //Handle Click Select values
  const handleClickScope = (scope) => {
    setCodiceScope(scope);
    setCodiceServizio(0);
    botRef.current = "";
    statoRef.current = 0;
    setStato("");
    setValueTabs(0);
  };

  const handleClickServizio = (codice) => {
    setCodiceServizio(codice);
    botRef.current = "";
    setValueTabs(0);
    setButtonClick(false);
  };

  const handleClickBot = (idUid) => {
    botRef.current = idUid;
  };

  const handleClickStato = (stato) => {
    statoRef.current = stato;
  }; //

  //Hanlde Click Nuovo Servizio
  const handleClickNuovoServizio = () => {
    setOpenModalNuovoServizio(true);
  };

  //Ricerca filtri, handle and useEffect
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  useEffect(() => {
    if (dataArray) {
      const filteredList = dataArray
        .filter(
          (row) =>
            (!botRef.current || row.idUidBOT === botRef.current) &&
            (!statoRef.current || row.Stato === statoRef.current)
        )
        .filter(
          (row) =>
            row.targa.includes(searchParam) || row.Note.includes(searchParam)
        );
      setFilteredUsersList(filteredList);
    }
  }, [searchParam, dataArray, botRef.current, statoRef.current]);

  //UseEffect that set states based on openFrom prop
  useEffect(() => {
    if (open) {
      initializeModalState(openFrom);
    }
  }, [open, openFrom]);

  //Handle snackbars
  const dispatch = useDispatch();
  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  //Fetch data function
  async function servicesGetDetails(data) {
    const token = sessionStorage.getItem("token");
    if (data.codice === 0) {
      setButtonClick(false);
    }
    if (data.idUIDBot === "") {
      setButtonClick(false);
    } else {
      setButtonClick(true);
    }

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: data,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesGetDetails",
        requestOptions
      );

      const responseData = await response.json();

      if (responseData.status === 0) {
        handleSnackbar("Errore: selezionare un servizio", "error");
        setDataArray([]);
        setFilteredUsersList([]);
      }
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      const array = responseData.data.dataset;
      const info = responseData.data.info;

      if (!array) {
        handleSnackbar("Errore: selezionare un servizio", "error");
      }
      if (!info) {
        setValueTabs(0);
      }
      setDataArray(array);
      setTotaleDati(array?.length);
      setInfo(info);
    } catch (error) {
      return error;
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px", // Set your width here
              backgroundColor:
                theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
            },
          },
        }}
      >
        <DialogTitle>Focus Servizi</DialogTitle>
        <DialogContent>
          <form
            style={
              isUnder600px
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }
                : { display: "flex", alignItems: "center" }
            }
          >
            <FormControl m={2} style={{ minWidth: 120 }} size="small">
              <InputLabel id="scope">Scope</InputLabel>
              <Select
                labelId="scope"
                id="scope"
                MenuProps={MenuProps}
                label="Scope"
                value={scope}
                size="small"
                onChange={handleChangeScope}
              >
                <MenuItem
                  value="Preventivi"
                  onClick={() => handleClickScope(0)}
                >
                  Preventivi
                </MenuItem>
                <MenuItem value="ania" onClick={() => handleClickScope(2)}>
                  Ania
                </MenuItem>
                <MenuItem value="Asset" onClick={() => handleClickScope(1)}>
                  Asset
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl m={2} style={{ minWidth: 120 }} size="small">
              <InputLabel id="servizio">Servizio</InputLabel>
              <Select
                labelId="servizio"
                id="servizio"
                MenuProps={MenuProps}
                label="Servizio"
                value={servizio}
                size="small"
                onChange={handleChangeServizio}
              >
                <MenuItem
                  onClick={handleClickNuovoServizio}
                  sx={{ height: 30 }}
                  value="Nuovo servizio"
                >
                  {/*Apri una nuova modale  */}
                  <ChipGreen label="Crea nuovo servizio" />
                </MenuItem>
                {dati.map(
                  (servizio) =>
                    servizio.ScopeDesc === scope && (
                      <MenuItem
                        key={servizio.Nome}
                        value={servizio.Nome}
                        onClick={() => handleClickServizio(servizio.Codice)}
                      >
                        {servizio.Nome}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ height: 35, flex: 1, mx: 2 }}
                variant="contained"
                onClick={handleClickButton}
              >
                <Search />
              </Button>
            </Box>
          </form>

          <Box sx={{ width: "100%" }} my={2}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={valueTabs}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
              >
                <Tab label={`Dettagli (${totaleDati ? totaleDati : 0})`} />
                <Tab
                  label="Settings"
                  disabled={buttonClick === false ? true : false}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={valueTabs} index={0}>
              {/* Dettagli TAB */}
              <Card mb={6}>
                <Box
                  sx={
                    isUnder600px
                      ? {
                          display: "flex",
                          flexDirection: "column",
                          p: 0,
                          my: 4,
                          mx: 2,
                          gap: 4,
                        }
                      : {
                          display: "flex",
                          justifyContent: "space-between",
                          p: 0,
                          my: 4,
                        }
                  }
                >
                  <Box
                    sx={
                      isUnder600px
                        ? {
                            display: "flex",
                            flexDirection: "column",

                            gap: 4,
                          }
                        : {
                            display: "flex",

                            gap: 4,
                          }
                    }
                  >
                    <FormControl style={{ minWidth: 150 }} size="small">
                      <InputLabel id="demo-mutiple-bot-label">Bot</InputLabel>
                      <Select
                        labelId="demo-mutiple-bot-label"
                        id="demo-mutiple-bot"
                        MenuProps={MenuProps}
                        label="Bot"
                        value={bot}
                        size="small"
                        onChange={handleChangeBot}
                      >
                        <MenuItem
                          onClick={() => handleClickBot("")}
                          sx={{ height: 30 }}
                          value=""
                        />
                        {dati.map(
                          (service) =>
                            service.Nome === servizio &&
                            service.dataset.map((bot) => (
                              <MenuItem
                                key={bot.nome}
                                value={bot.nome}
                                onClick={() => handleClickBot(bot.idUid)}
                              >
                                {bot.nome}
                              </MenuItem>
                            ))
                        )}
                      </Select>
                    </FormControl>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <InputLabel id="demo-mutiple-stato-label">
                        Stato
                      </InputLabel>
                      <Select
                        labelId="demo-mutiple-stato-label"
                        id="demo-mutiple-stato"
                        MenuProps={MenuProps}
                        label="Stato"
                        value={stato}
                        size="small"
                        onChange={handleChangeStato}
                      >
                        <MenuItem
                          onClick={() => handleClickStato(0)}
                          sx={{ height: 30 }}
                          value=""
                        />
                        {stati.map((stato) => (
                          <MenuItem
                            key={stato.nome}
                            value={stato.nome}
                            onClick={() => handleClickStato(stato.codice)}
                          >
                            {stato.nome}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <FormControl>
                    <TextField
                      size="small"
                      margin="none"
                      label="Ricerca"
                      onChange={(e) => handleSearch(e)}
                    />
                  </FormControl>
                </Box>

                <CardContent sx={{ p: 0 }}>
                  <div style={{ width: "100%", height: 255 }}>
                    <DataGrid
                      rowsPerPageOptions={[5, 10, 20]}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      columns={columns(codiceScope)}
                      getRowId={(row) => row.iduid}
                      rowHeight={30}
                      pagination
                      headerHeight={30}
                      rows={filteredUsersList}
                      initialState={{
                        columns: {
                          columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            iduid: false,
                          },
                        },
                      }}
                      autosizeOptions={{
                        columns: ["Note"],
                        includeOutliers: true,
                        includeHeaders: true,
                      }}
                    />
                  </div>
                </CardContent>
              </Card>
            </CustomTabPanel>

            <CustomTabPanel value={valueTabs} index={1}>
              <Settings
                info={info}
                codiceServizio={codiceServizio}
                codiceScope={codiceScope}
              />
            </CustomTabPanel>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{ marginRight: "8px" }}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      <NuovoServizio
        open={openModalNuovoServizio}
        setOpen={setOpenModalNuovoServizio}
      />
    </React.Fragment>
  );
};
export default Modale;

import React from 'react'
import DynamicForm from './DynamicForm'
import DynamicTable from './DynamicTable'
import { Accordion, AccordionDetails, AccordionSummary, Box, CardContent, Divider, Typography, useMediaQuery } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import ComplexTable from './ComplexTable'

const DynamicComponent = ({
    nome,
    rcDetails,
    dataset,
    handleInputChange,
    posizione
}) => {
    const isMobile = useMediaQuery("(max-width:600px)")

    //nome=Overview | Altri Rami | Preventivi
    const tab = rcDetails?.tabs?.filter(el => el[nome])[0][nome]
    const arrayData = Object.entries(tab)
    const sorted = arrayData.sort((a, b) => a[1].position - b[1].position)

    return (
        <CardContent>
            {
                sorted.map((el) => {
                    const { componentType } = el[1]
                    const hasSection = Boolean(el[1].section)

                    if (componentType === "form") {
                        const hasSection = Boolean(el[1].section)
                        const name = hasSection ? Object.keys(el[1].section)[0] : ""
                        const insideSectionObj = hasSection ? el[1].section[name] : null

                        const handleDateChange = (date, fieldName) => {
                            handleInputChange(
                                "Overview",
                                fieldName,
                                date ? date.toLocaleDateString("it-IT") : ""
                            );
                        };
                        return (
                            <Accordion defaultExpanded={!isMobile && (el[0] === "Cliente" || el[0] === "Veicolo")}>
                                <AccordionSummary expandIcon={<ExpandMore />}><Typography>{el[0]}</Typography></AccordionSummary>

                                <AccordionDetails>
                                    <DynamicForm handleInputChange={handleInputChange} handleDateChange={handleDateChange} parentName={nome} name={el[0]} dataset={el[1].dataset}sectName={name} section={insideSectionObj} />
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                    else if (componentType === "table") {
                        const hasSection = Boolean(el[1].section)
                        const name = hasSection ? Object.keys(el[1].section)[0] : ""
                        const insideSectionObj = hasSection ? el[1].section[name] : null

                        console.log(el[1].dynamic)

                        return (
                            <Accordion defaultExpanded={!isMobile && (el[0] === "Attestato")}>
                                <AccordionSummary expandIcon={<ExpandMore />}><Typography>{el[0]}</Typography></AccordionSummary>

                                <AccordionDetails>
                                    <Box my={6}>
                                        {Boolean(el[1].dynamic)
                                            ? (<DynamicTable name={el[0]} dataset={el[1].dataset} section={hasSection ? insideSectionObj : null} sectName={name} />)
                                            : <><DynamicTable name={el[0]} dataset={el[1].dataset} /></>}
                                    </Box>
                                    <Divider my={6} />
                                    <Typography variant="h6" mt={6}>{name}</Typography>
                                    <Box my={6}>
                                        {hasSection && (<ComplexTable name={el[0]} dataset={el[1].dataset} section={hasSection ? insideSectionObj : null} sectName={name} />)}

                                    </Box>
                                </AccordionDetails>
                            </Accordion>)
                    }
                })
            }
        </CardContent>
    )
}

export default DynamicComponent

import React, { useEffect, useState } from "react";
import { Typography, Grid, FormControl, Divider, Button, Chip } from "../style";
import { TextField, CardContent, Paper, Accordion, AccordionSummary, Box, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { minWidth } from "@mui/system";
import UserNE from "../nonEditables/UserNE";
import VehicleNE from "../nonEditables/VehicleNE";
import { AttestatoTable, CopertureTable, ProprietaTable, SinistriTable } from "./Tables";

export const parseDate = (dateString) => {
  if (!dateString) return null;
  const [day, month, year] = dateString.split("/");
  return new Date(year, month - 1, day);
};
export const estraiObj = (array, key) => {
  for (const obj of array) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }
  }
  return null;
}
const sortByPos = (dataset) => {
  const sorted = dataset?.sort((a, b) => a.position - b.position)
  return sorted
}

const Veicolo = ({
  vehiclePlcholders,
  handleInputChange,
  tabData,
  handleSubmit,
  laterals,
  activeTab,
  rcDetails,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)")

  const [formData, setFormData] = useState([]);
  const [propertyTableData, setPropertyTableData] = useState([]);
  const [clienteData, setClienteData] = useState([])
  const [coverageTableData, setCoverageTableData] = useState([]);
  const [attestatoTableData, setAttestatoTableData] = useState([]);
  const [sinistriTableData, setSinistriTableData] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [pageSize, setPageSize] = useState(5);




  useEffect(() => {
    if (rcDetails && rcDetails.tabs) {
      const veicolo = estraiObj(rcDetails.tabs, "Overview")

      console.log(rcDetails)
      const formDataFromAPI = veicolo.Veicolo.dataset || []
      //const clienteDataFromApi = veicolo.Cliente
      const propertyTableDataFromAPI = veicolo.Proprietà.dataset || [];
      const coverageTableDataFromAPI = veicolo.Coperture.dataset || [];
      const attestatoTableDataFromAPI = veicolo.Attestato.dataset || [];
      const sinistriTableDataFromAPI = veicolo.Sinistri.dataset || [];
      const clienteDataAPI = veicolo.Cliente.dataset
      const addIdToRows = (rows) => rows?.map((row, index) => ({ ...row, id: index }));

      setFormData(sortByPos(formDataFromAPI));
      setPropertyTableData(addIdToRows(propertyTableDataFromAPI));
      setCoverageTableData(addIdToRows(coverageTableDataFromAPI));
      setAttestatoTableData(addIdToRows(attestatoTableDataFromAPI));
      setSinistriTableData(addIdToRows(sinistriTableDataFromAPI));
      setClienteData(addIdToRows(clienteDataAPI));

      const initialValues = {};
      vehiclePlcholders.forEach((vehicle) => {
        let value = vehicle.value;

        if (
          vehicle.label === "Data Acquisto" ||
          vehicle.label === "Data Immatricolazione" ||
          vehicle.label === "Inizio copertura" ||
          vehicle.label === "Fine copertura"
        ) {
          value = parseDate(vehicle.value);
        }

        initialValues[vehicle.label] = value;
      });

      handleInputChange("Overview", initialValues);
    }
  }, [rcDetails, vehiclePlcholders, handleInputChange]);

  const handleDateChange = (date, fieldName) => {
    handleInputChange(
      "Overview",
      fieldName,
      date ? date.toLocaleDateString("it-IT") : ""
    );
  };

  const renderInputField = (vehicle) => {
    const value = tabData.values[vehicle.label] || vehicle.value || null;

    if (
      vehicle.label === "Data Acquisto" ||
      vehicle.label === "Data Immatricolazione" ||
      vehicle.label === "Inizio copertura" ||
      vehicle.label === "Fine copertura"
    ) {
      return (
        <DatePicker
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          disabled={!Boolean(vehicle.editable)}
          value={value instanceof Date ? value : parseDate(vehicle.value)}
          onChange={(date) => handleDateChange(date, vehicle.label)}
          renderInput={(params) => <TextField {...params} fullWidth />}
          inputFormat="dd/MM/yyyy"
        />
      );
    } else {
      return (
        <TextField
          disabled={!vehicle.editable}
          label={`${vehicle.label} ${!vehicle.editable ? "" : "*"}`}
          name={vehicle.label}
          value={value}
          onChange={(event) =>
            handleInputChange("Overview", vehicle.label, event.target.value)
          }
          fullWidth
        />
      );
    }
  };


  const generateRowId = (row) => row.id;

  const disabled = vehiclePlcholders?.map(vehicle => {
    if (vehicle.editable) {
      return false
    } else return true
  })

  const sinistriTabData = rcDetails?.tabs?.Overview?.Sinistri?.dataset?.map(({ anno, ...resto }) => resto);
  // Ordina i componenti di vehiclePlcholders per posizione

  console.log(clienteData)
  return (
    <>
      <CardContent>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Cliente</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", justifyContent: "center", alignItems: "end", flexDirection: "column" }} >
            <Grid container spacing={3}>
              {clienteData?.map((vehicle) => (
                <Grid item xs={12} sm={4} key={vehicle.label}>
                  <FormControl fullWidth>{renderInputField(vehicle)}</FormControl>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              my={6}
              sx={{ width: isMobile ? "100%" : "fit-content", fontSize: "16px" }}
              disabled={disabled}
              onClick={() => handleSubmit(activeTab)}
            >
              Salva
            </Button>
          </AccordionDetails>
        </Accordion>
        <Accordion >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Veicolo</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", justifyContent: "center", alignItems: "end", flexDirection: "column" }} >
            <Grid container spacing={3}>
              {vehiclePlcholders?.map((vehicle) => (
                <Grid item xs={12} sm={4} key={vehicle.label}>
                  <FormControl fullWidth>{renderInputField(vehicle)}</FormControl>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              my={6}
              sx={{ width: isMobile ? "100%" : "fit-content", fontSize: "16px" }}
              disabled={disabled}
              onClick={() => handleSubmit(activeTab)}
            >
              Salva
            </Button>
          </AccordionDetails>
        </Accordion>

        {/* Renderizza i componenti ordinati in base alla loro posizione */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Attestato</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AttestatoTable data={attestatoTableData} />

            <Divider my={6} />

            <Typography variant="h6" my={6} >Sinistri</Typography>
            <SinistriTable data={sinistriTableData} />

          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Proprietà</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProprietaTable data={propertyTableData} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Coperture</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CopertureTable data={coverageTableData} />
          </AccordionDetails>
        </Accordion>

      </CardContent>
    </>
  );
};

export default Veicolo;

import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  height: 269px;
  position: relative;
`;

const BigNumber = ({ totalRows }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Card mb={isDesktop ? 0 : 6}>
      <CardHeader title="Visure" />
      <CardContent>
        <TableWrapper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize={100}>{totalRows}</Typography>
          </Box>
        </TableWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BigNumber);

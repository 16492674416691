import React from "react";
import { Box, Typography } from "@mui/material";

function ConfirmationStep({ tableData }) {
  // Filter out the rejected license plates
  const validatedPlates = tableData.filter((item) => item.error === false);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" mb={2} mt={2}>
        Si desidera procedere con il caricamento delle targe valide?
      </Typography>
      <Typography>
        {`Sono state trovate ${validatedPlates?.length} targhe valide da un totale di ${tableData?.length}.`}
      </Typography>
    </Box>
  );
}

export default ConfirmationStep;

import { FormControl, TextField, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { parseDate } from '../tabs/Veicolo'; 
import { Button, Divider, Grid, Typography } from '../style';

const DynamicForm = ({
    parentName,
    handleDateChange,
    handleInputChange,
    dataset,
    section,
    sectName
}) => {
    const [errors, setErrors] = useState({});
    const theme = localStorage.getItem("theme");

    const validateField = (data) => {
        let error = '';
        if (data.regex && data.value && !new RegExp(data.regex).test(data.value)) {
            error = data.errRegex || 'Formato non corretto';
        }
        return error;
    };

    const handleFieldChange = (fieldName, value) => {
        handleInputChange(parentName, fieldName, value);
        
        const newErrors = { ...errors };
        const field = dataset.find((el) => el.label === fieldName);
        if (field) {
            const error = validateField({ ...field, value });
            if (error) {
                newErrors[fieldName] = error;
            } else {
                delete newErrors[fieldName];
            }
        }
        setErrors(newErrors);
    };

    const handleDateFieldChange = (date, fieldName) => {
        const formattedDate = date instanceof Date && !isNaN(date) ? date : null;
        const formattedValue = formattedDate && `${("0" + formattedDate.getDate()).slice(-2)}/${("0" + (formattedDate.getMonth() + 1)).slice(-2)}/${formattedDate.getFullYear()}`;

        if (formattedDate) {
            handleInputChange(parentName, fieldName, formattedValue); 
        }

        const newErrors = { ...errors };
        const field = dataset.find((el) => el.label === fieldName);
        if (field) {
            const error = validateField({ ...field, value: formattedValue });
            if (error) {
                newErrors[fieldName] = error;
            } else {
                delete newErrors[fieldName];
            }
        }
        setErrors(newErrors);
    };

    console.log(theme)

    const renderInputField = (data) => {
        const value = data.value;
        const error = errors[data.label];
        const hasError = !!error;

        const inputStyles = {
            '&.Mui-disabled': {
                color: 'rgba(0, 0, 0, 0.70)', 
                '& .MuiInputBase-input': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.70)', 
                },
            },
        };
        const normal = {
            '&.Mui-disabled': {
                color: 'rgb(255, 255, 255)', 
                '& .MuiInputBase-input': {
                    WebkitTextFillColor: 'rgb(255, 255, 255)', 
                },
            },
        };

        if (
            data.label === "Data Acquisto" ||
            data.label === "Data Immatricolazione" ||
            data.label === "Inizio copertura" ||
            data.label === "Fine copertura" ||
            data.label === "Data fondazione"
        ) {
            return (
                <DatePicker
                    label={`${data.label} ${data.required ? "*" : ""}`}
                    name={data.label}
                    disabled={!Boolean(data.editable)}
                    value={value instanceof Date ? value : parseDate(data.value)}
                    onChange={(date) => handleDateFieldChange(date, data.label)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            error={hasError}
                            helperText={hasError ? error : ''}
                            InputProps={theme == '"LIGHT"' ? { sx: inputStyles } : {sx:normal}} 
                        />
                    )}
                    inputFormat="dd/MM/yyyy"
                />
            );
        } else {
            return (
                <TextField
                    disabled={!data.editable}
                    label={`${data.label} ${data.required ? "*" : ""}`}
                    name={data.label}
                    value={value}
                    onChange={(event) => handleFieldChange(data.label, event.target.value)}
                    fullWidth
                    error={hasError}
                    helperText={hasError ? error : ''}
                    InputProps={theme == '"LIGHT"' ? { sx: inputStyles } : {sx:normal}} 
                />
            );
        }
    };

    const validateForm = () => {
        const newErrors = {};
        dataset.forEach((el) => {
            const error = validateField(el);
            if (error) {
                newErrors[el.label] = error;
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isBtnDisabled = dataset.some(el => el.editable);

    const handleSubmit = () => {
        if (validateForm()) {
            console.log('Form submitted successfully');
        } else {
            console.log('Form contains errors');
        }
    };

    return (
        <Grid container spacing={3}>
            {dataset.map(el => (
                <Grid item xs={12} sm={4} key={el.label}>
                    <FormControl fullWidth>{renderInputField(el)}</FormControl>
                </Grid>
            ))}
            {section && (
                <Box sx={{ translate: "12px 0" }}>
                    <Divider my={6} />
                    <Typography my={6}>{sectName}</Typography>
                    <Grid container spacing={3}>
                        {section?.dataset?.map(el => (
                            <Grid item xs={12} sm={4} key={el.label}>
                                <FormControl fullWidth>{renderInputField(el)}</FormControl>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            <Button
                variant="outlined"
                mt={5}
                ml={3}
                disabled={!isBtnDisabled}
                onClick={handleSubmit}
            >
                Salva
            </Button>
        </Grid>
    );
};

export default DynamicForm;

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  FilterList as FilterIcon,
  FilterListOff as FilterOffIcon,
} from "@mui/icons-material";
import React, { useState } from "react";

const Filters = ({
  filtersLoading,
  handleApplyFilters,
  handleRemoveFilters,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [filtersActive, setFiltersActive] = useState(isDesktop);
  const [ragioneSociale, setRagioneSociale] = useState("");
  const [piva, setPiva] = useState("");

  const switchFilterActive = () => {
    setFiltersActive((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters(ragioneSociale, piva);
  };

  const handleChangeRS = (e) => {
    setRagioneSociale(e.target.value);
  };
  const handleChangePIVA = (e) => {
    setPiva(e.target.value);
  };

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "8px 16px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
              alignItems: "start",
              padding: "1rem",
            },
          }}
        >
          {!filtersActive ? (
            <Stack
              direction="row"
              sx={{
                cursor: "pointer",
                userSelect: "none",
                padding: "15px 0",
                [theme.breakpoints.down("md")]: {
                  padding: "0",
                },
              }}
              onClick={switchFilterActive}
            >
              <FilterIcon
                sx={{
                  marginRight: "16px",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "4px",
                  },
                }}
              />
              <Typography variant="h4" mr={4}>
                Filtra
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              sx={{
                cursor: "pointer",
                userSelect: "none",
                padding: "15px 0",
                [theme.breakpoints.down("md")]: {
                  padding: "0",
                },
              }}
              onClick={switchFilterActive}
            >
              <FilterOffIcon
                sx={{
                  marginRight: "16px",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "4px",
                  },
                }}
              />
              {/* <Typography variant="h4" mr={4}>
              Filtra
            </Typography> */}
            </Stack>
          )}

          {filtersActive && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  alignItems: "end",
                  width: "100%",
                },
              }}
            >
              <TextField
                size="small"
                label="Ragione Sociale"
                name="Ragione Sociale"
                onChange={handleChangeRS}
                sx={{
                  width: "200px",
                  margin: "8px",
                  minWidth: "200px",
                  [theme.breakpoints.down(1500)]: {
                    width: "120px",
                  },
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "0",
                    margin: "8px 0",
                    width: "100%",
                  },
                }}
              />
              <TextField
                size="small"
                label="P. IVA"
                name="P. IVA"
                onChange={handleChangePIVA}
                sx={{
                  width: "200px",
                  margin: "8px",
                  minWidth: "200px",
                  [theme.breakpoints.down(1500)]: {
                    width: "120px",
                  },
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "0",
                    margin: "8px 0",
                    width: "100%",
                  },
                }}
              />
              <Button
                variant="contained"
                type="submit"
                sx={{
                  margin: "8px",
                  [theme.breakpoints.down("md")]: {
                    marginBottom: "8px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    margin: "4px 0 0 0",
                  },
                }}
              >
                {filtersLoading ? <CircularProgress size={22} /> : "Cerca"}
              </Button>
            </Box>
          )}
        </Box>
      </form>
    </Card>
  );
};

export default Filters;

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { Chip, Paper } from '../style';

export const SinistriTable = ({ data }) => {
    const years = data.map((item) => item.anno);

    return (
        <TableContainer component={Paper} sx={{ border: '2px solid gray' }}>
            <Table aria-label="sinistri table">
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2} align="center" colSpan={1}>Sinistri</TableCell>
                        {years.map((year, index) => (
                            <TableCell align="center" key={index}>{year}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody sx={{ fontSize: "10px" }}>
                    <TableRow colSpan={years.length + 1} style={{ fontWeight: 'bold', translateX: "-5px", backgroundColor: '#0366fc', width: "100%" }}>
                        <TableCell >Sinistri pagati con responsabilità principale</TableCell>
                        {data.map((row, index) => {
                            return row.principali > 0 ? (<TableCell><Chip sx={{ backgroundColor: "#f44336", translate: "5px 0" }} label={row.principali} /></TableCell>) : <TableCell sx={{ translate: "12px 0" }}>{row.principali}</TableCell>
                        }
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a COSE</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center"  >
                                {row.principaliCose}
                            </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a PERSONE</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center" >
                                {row.principaliPersone}
                            </TableCell>))}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a MISTI (persone+cose)</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center" >
                                {row.principaliMisti}
                            </TableCell>))}
                    </TableRow>
                    <TableRow colSpan={years.length + 1} style={{ fontWeight: 'bold', translateX: "-5px", backgroundColor: '#0366fc', width: "100%" }}>
                        <TableCell>Sinistri pagati con responsabilità paritaria</TableCell>
                        {data.map((row, index) => {
                            return row.paritari > 0 ? (<TableCell><Chip sx={{ background: "#ffa726", translate: "5px 0" }} label={row.paritari} /></TableCell>) : <TableCell sx={{ translate: "12px 0" }}>{row.paritari}</TableCell>
                        }
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a COSE</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center" >
                                {row.paritariCose}
                            </TableCell>))}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a PERSONE</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center" >
                                {row.paritariPersone}
                            </TableCell>))}
                    </TableRow>
                    <TableRow>
                        <TableCell>di cui con danni a MISTI (persone+cose)</TableCell>
                        {data.map((row, index) => (
                            <TableCell key={index} align="center" >
                                {row.paritariMisti}
                            </TableCell>))}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const DynamicTable = (
    { name, dataset, sinistri }
) => {
    const headers = dataset.map(el => Object.keys(el))[0]
    const data = dataset.map(el => Object.entries(el))[0]

    return (
        <>
            {sinistri ? (<SinistriTable data={dataset} />) : (<TableContainer component={Paper}>
                <Table aria-label={name} sx={{ border: "2px solid gray" }}>
                    <TableHead>
                        <TableRow>
                            {headers.map(h => (
                                <TableCell>{h}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {data.map(el => (
                                <TableCell>{el[1]}</TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>)}
        </>

    )
}

export default DynamicTable
